var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"product-card",class:{ 'on-hover': hover },attrs:{"outlined":"","rounded":"md"}},[_c('v-flex',{staticClass:"flex-column",on:{"click":_vm.logClickProduct}},[_c('v-flex',{staticClass:"flex-column"},[_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{attrs:{"src":_vm.product.mediaURL,"contain":"","width":_vm.$vuetify.breakpoint.xs ? 110 : 170,"height":_vm.$vuetify.breakpoint.xs ? 110 : 170,"alt":'Immagine' + _vm.product.name,"title":_vm.product.name +
                  ' (' +
                  _vm.product.codInt +
                  '-' +
                  _vm.product.codVar +
                  ')'}},[(_vm.product.productInfos.IS_SURGELATO == 'true')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-flex justify-end pr-16"},'div',attrs,false),on),[_c('img',{attrs:{"aria-hidden":"","alt":"Immagine surgelato","src":"/product-info/surgelato.svg"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("product.frozen")))])]):_vm._e()],1)],1),_c('v-col',{staticClass:"d-flex flex-column text-left product-info",attrs:{"cols":"8"}},[_c('div',{staticClass:"select-product w-100 d-flex justify-space-between"},[(
                  _vm.product.pubState === 1 && _vm.product.web_enabled === 'false'
                )?_c('div',{staticClass:"triangle"},[_c('div',{staticClass:"triangle-text"},[_vm._v(_vm._s(_vm.$t("common.delisted")))])]):_c('v-tooltip',{key:_vm.heartKey,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addToCarello(_vm.check)},"mousedown":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[(_vm.product.inCart)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cart")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cart-outline")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.product.inCart ? _vm.$t("product.removeFromCart") : _vm.$t("product.addToList"))+" ")])],1),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("advanceSearch.brand"))+" ")]),_c('div',{staticClass:"info-title",staticStyle:{"min-height":"25px"},domProps:{"innerHTML":_vm._s(_vm.product.shortDescr)}}),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("product.details.description"))+" ")]),_c('div',{staticClass:"info-details mb-0",staticStyle:{"min-height":"73px"},domProps:{"innerHTML":_vm._s(_vm.product.name)}}),_c('div',{staticClass:"d-flex flex-row justify-end px-3"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","text":"","to":{ name: 'Product', params: { slug: _vm.product.slug } }}},[_vm._v(_vm._s(_vm.$t("common.details"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-arrow-right ")])],1)],1)])],1)],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }